



















































import { Inject } from 'inversify-props';
import { Vue, Component } from 'vue-property-decorator';
import { ChartData, ChartOptions } from 'chart.js';
import moment from 'moment';
import DeepCompsetAnalysisService, { DeepCompsetAnalysisServiceS } from '@/modules/deep-analysis/deep-analysis.service';
import ModalWrapper from '@/modules/common/components/modal-wrapper.vue';
import CustomGraph from '@/modules/common/components/ui-kit/custom-graph/graph.vue';
import DeepAnalisysGraphTooltip from '@/modules/deep-analysis/components/hotel/deep-analysis-graph-tooltip.vue';
import PriceFilter from '@/modules/common/filters/price.filter';

interface RoundedChartOptions extends ChartOptions {
    barRoundness: number
}

@Component({
    components: {
        ModalWrapper,
        CustomGraph,
        DeepAnalisysGraphTooltip,
    },
    filters: {
        DateFilter: (date: Date) => moment(date).format('MMM, D YYYY'),
    },
})
export default class DeepAnalysisModal extends Vue {
    @Inject(DeepCompsetAnalysisServiceS) deepCompsetAnalysisService!: DeepCompsetAnalysisService;

    get updatedDate() {
        if (this.deepCompsetAnalysisService.provider === 'expedia' && this.item.updatedDateField) {
            return this.item.updatedDateField;
        }
        return this.item.updatedAt;
    }

    get item() {
        const { id } = this.$route.params;
        return this.deepCompsetAnalysisService.data[Number(id) - 1];
    }

    get labels() {
        const { dates } = this.item.chartData;
        return [...dates].map((date, i) => {
            const month = moment(date).format('MMM');
            const day = moment(date).format('D');
            return i === 0 || i === dates.length - 1 ? [day, month] : day;
        });
    }

    get brandData() {
        const { data } = this.item.chartData;
        return [...data].reverse()
            .map(item => item.brand);
    }

    get compsetData() {
        const { data } = this.item.chartData;
        return [...data].reverse()
            .map(item => item.compsetAvg);
    }

    get chartData(): ChartData {
        if (!this.item) {
            return {
                labels: [],
                datasets: [],
            };
        }
        return {
            labels: this.labels,
            datasets: [{
                label: 'Brand',
                backgroundColor: '#A1A2FF',
                borderWidth: 0,
                barThickness: 8,
                minBarLength: 5,
                data: this.brandData,
            }, {
                label: 'Comp set Avg.',
                backgroundColor: '#E5E5E5',
                borderWidth: 0,
                barThickness: 8,
                minBarLength: 5,
                data: this.compsetData,
            }],
        };
    }

    get options(): RoundedChartOptions {
        let isOver100k = false;
        return {
            barRoundness: 1,
            maintainAspectRatio: false,
            legend: {
                display: false,
            },
            plugins: {
                filler: {
                    propagate: true,
                },
            },
            tooltips: {
                enabled: false,
            },
            scales: {
                xAxes: [{
                    gridLines: {
                        display: true,
                        borderDash: [0, 1],
                        offsetGridLines: true,
                        color: '#ECF1F5',
                        zeroLineWidth: 0,
                    },
                    ticks: {
                        minor: {
                            fontSize: 12,
                        },
                    },
                }],
                yAxes: [{
                    gridLines: {
                        display: true,
                        offsetGridLines: true,
                        borderDash: [0, 4],
                        color: '#ECF1F5',
                        zeroLineWidth: 0,
                    },
                    ticks: {
                        maxTicksLimit: 5,
                        autoSkip: true,
                        // stepSize: 1,
                        min: 0,
                        minor: {
                            fontSize: 12,
                        },
                        callback(value: number) {
                            if (value >= 100000 || isOver100k) {
                                isOver100k = true;
                                return `${Math.round(value / 1000)}K`;
                            }

                            return PriceFilter(value, 0);
                        },
                    },
                }],
            },
        };
    }
}
